import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { FiPower, FiTrash2 } from "react-icons/fi"

import api from "../../services/api"

import logoImg from "../../assets/logo.svg"

import "./style.css"

function Profile() {
    const [incidents, setIncidents] = useState([])

    const ongName = localStorage.getItem("ongName")
    const ongID = localStorage.getItem("ongId")

    const history = useHistory()

    useEffect(() => {
        api.get("profile", {
            headers: {
                authorization: ongID
            }
        }).then(res => setIncidents(res.data))
    }, [ongID])

    async function handleDeleteIncident(id) {
        try {
            await api.delete(`/incidents/${id}`, {
                headers: {
                    authorization: ongID
                }
            })

            setIncidents(incidents.filter(f => f.id !== id))
        }catch(err){
            alert("Error ao deletar o caso. Tente novamente.")
            throw err
        }
    }

    function handleLogout() {
        localStorage.clear()

        history.push("/")
    }

    return (
        <div className="profile-container">
            <header>
                <img src={logoImg} alt="Be the Hero" />
                <span>Bem vinda, {ongName}</span>

                <Link className="button" to="/incidents/new">Cadastrar novo caso</Link>
                <button onClick={handleLogout} type="button">
                    <FiPower size={18} color="#e02041" />
                </button>
            </header>

            <h1>Casos Cadastrados</h1>

            <ul>
                {incidents.reverse().map(incident => (
                    <li key={incident.id}>
                        <strong>CASO:</strong>
                        <p>{incident.title}</p>

                        <strong>DESCRIÇÃO:</strong>
                        <p>{incident.description}</p>

                        <strong>VALOR:</strong>
                        <p>{Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(incident.value)}</p>

                        <button onClick={() => handleDeleteIncident(incident.id)} type="button">
                            <FiTrash2 size={20} color="#a8a8b3" />
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Profile