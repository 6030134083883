import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { FiArrowLeft } from "react-icons/fi"

import logoImg from "../../assets/logo.svg"

import api from "../../services/api"

import "./style.css"

function NewIncident() {
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [value, setValue] = useState("")

    const history = useHistory()

    async function handleRegisterIncident(e) {
        e.preventDefault()

        const data = {
            title, description, value
        }

        try {
            await api.post("incidents", data, { headers: { authorization: localStorage.getItem("ongId") } })

            history.push("/profile")
        }catch(err){
            alert("Falha no registro de caso. Tente novamente.")
            throw err
        }

    }

    return (
        <div className="new-incident-container">
            <div className="content">
                <section>
                    <img src={logoImg} alt="Be The Hero" />

                    <h1>Cadastrar novo caso</h1>
                    <p>Descreva detalhadamente para encontrar um heroí para resolver isso.</p>

                    <Link className="back-link" to="/profile">
                        <FiArrowLeft size={16} color="#e02041" />
                        Voltar para home
                    </Link>
                </section>

                <form onSubmit={handleRegisterIncident}>
                    <input onChange={e => setTitle(e.target.value)} value={title} placeholder="Título do caso" />
                    <textarea onChange={e => setDescription(e.target.value)} value={description} placeholder="Descrição"></textarea>
                    <input onChange={e => setValue(e.target.value)} value={value} placeholder="Valor em reais"/>

                    <button className="button" type="submit">Cadastrar</button>
                </form>
            </div>
        </div>
    )
}

export default NewIncident