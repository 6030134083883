import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { FiLogIn } from "react-icons/fi"

import "./style.css"

import logoImg from "../../assets/logo.svg"
import heroesImg from "../../assets/heroes.png"

import api from "../../services/api"

function Logon() {
    const [id, setID] = useState("")

    const history = useHistory()

    async function handleLogon(e) {
        e.preventDefault()

        try {
            const res = await api.post("sessions", { id })

            localStorage.setItem("ongId", id)
            localStorage.setItem("ongName", res.data.name)

            history.push("/profile")
        }catch(err){
            alert("Falha no login, tente novamente.")
            throw err
        }
    }

    return (
        <div className="logon-container">
            <section className="form">
                <img src={logoImg} alt="Be The Hero" />

                <form onSubmit={handleLogon}>
                    <h1>Faça o seu logon</h1>

                    <input placeholder="sua ID" value={id} onChange={e => setID(e.target.value)} />
                    <button className="button" type="submit">Entrar</button>

                    <Link className="back-link" to="/register">
                        <FiLogIn size={16} color="#e02041" />
                        Não tenho cadastro
                    </Link>
                </form>
            </section>

            <img src={heroesImg} alt="Heroes" />
        </div>
    )
}

export default Logon